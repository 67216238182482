.app {
  .top {
    height: 60px;
    text-align: right;
    line-height: 2.5;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f4f4;
    color: "#000";
  }

  .bottom {
    text-align: center;
    line-height: 2.5;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e9e9e9;
    color: #7d7d7d;
    border-top: 4px solid #e8b112;
    padding-top: 10px;
  }

  .bottomMenuItemHidden {
    display: none;
  }

  .bottomMenuItem {
    display: block;
  }
}

.MuiDrawer-paperAnchorRight{
  width: 340px;
  margin-top:60px;
  padding: 10px;
}
.bottomMenuItemHidden {
  display: none;
}

.bottomMenuItem {
  display: block;
}

.ovFooterRow {
  padding: 10px;
  background-color: #e9e9e9 !important;
  width: 100% !important;
}

.notificationAlertFacepileRed {
  background: rgb(238, 17, 17);
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  font-weight: normal;
  line-height: 1.6em;
  margin-right: 1px;
  text-align: center;
  width: 1.6em;
  position: absolute !important;
  top: 0px !important;
  right: 0px !important;
  cursor: pointer;
}

.notificationAlertFacepileRedImportant {
  background: rgb(238, 17, 17);
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  font-weight: normal;
  line-height: 1.6em;
  margin-right: 1px;
  text-align: center;
  width: 1.6em;
  position: absolute !important;
  top: 0px !important;
  right: 0px !important;
  cursor: pointer;
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.notificationAlertFacepileGreen {
  background: rgb(50, 205, 50);
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  font-weight: normal;
  line-height: 1.6em;
  margin-right: 1px;
  text-align: center;
  width: 1.6em;
  position: absolute !important;
  top: 0px !important;
  right: 0px !important;
  cursor: pointer;
}

.notificationAlertIcon {
  cursor: pointer;
  font-size: x-large;
}

.ovHeaderDiv {
  width: 100%;
  height: 100%;
  text-align: right;
  display: inline-block;
  background-color: rgb(244, 244, 244);
  color: "#000";
}

.ovHeaderItem {
  padding: 0px !important;
  float: left !important;
  position: relative;
  color: "#000" !important;
}

.ovHeaderLogoItem {
  height: 32px !important;
  width: 200px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 6px;
  margin-right: 10px;

  z-index: 1000 !important;

  background-image: url("https://duvalschoolsorg.sharepoint.com/sites/DHR/SiteAssets/OneView/img/OneviewLogoIcon1.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat !important;
  cursor: pointer !important;
}

.ovHeaderLogoContainer {
  float: left;
  position: relative;
}

.headerMenu {
  background-color: #e9e9e9;
}

.ovHeaderLogoItemLink {
  float: left;
  position: relative;
  left: 20px !important;
  padding: 4px !important;
  top: 4px !important;
  line-height: normal;
}

.ovHeaderActionItems {
  flex: 0 0 auto;
  position: relative;
  text-align: center;
  vertical-align: middle;
  //  right: 10px !important;
  padding: 4px !important;
  color: #ffffff;
}

.ovHeaderActionSchools {
  padding: 4px !important;
}

.ovSchoolsMegaMenu {
  padding: 0px !important;
}

.ovFooterDiv {
  height: 30px;
  width: 100%;
  text-align: left;
  font-weight: bold;
  background-color: #f4f4f4;
  color: #000;
}

.ovFooterItem {
  padding-left: 10px;
  float: left !important;
  position: relative;
}

.ovCardSection {
  padding: 5px;
  margin: 20px 0;
  text-align: left;
  border-radius: 2px;
  box-shadow: rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px,
    rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px;
}

.ovCardHeader {
  display: inline-block !important;
}

.ovCardTitle {
  color: #000 !important;
  font-weight: bold;
  font-size: 14px !important;
}

.ovIFrameTitle {
  color: red !important;
  font-weight: bold;
  font-size: 14px !important;
}

.ovCardDescription {
  color: #000 !important;
  font-weight: normal;
  font-size: 14px !important;
}

.ovCardLink {
  font-weight: bold;
  font-size: 12px !important;
  float: right !important;
  padding: 10px 0;
}

.ovCardIcon {
  color: rgb(0, 120, 212) !important;
  font-size: 16px !important;
}

.ovFooterCardSectionStyles {
  border-left: 1px solid #f3f2f1 !important;
}

.ovCardItem {
  color: #000 !important;
  padding: "10px" !important;
  margin: "10px" !important;
}

.ovClassCardsContainer {
  margin: "10px" !important;
  padding: "10px" !important;
}

.ovSchoolsGrid {
  padding: 10px;
  background-color: #e5eef5 !important;
  width: 100% !important;
}

.ovSchoolsGridRow {
  width: 100% !important;
}

.ovSchoolsGridLink {
  padding: 5px;
  color: #000;
  text-decoration: none !important;
  cursor: pointer;
}

.ovHidden {
  visibility: hidden;
}
